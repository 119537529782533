<template>
  <Configurator></Configurator>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Configurator from "../components/configurator/Configurator.vue";
import { getUrlQuerySearchParams } from "@/services/util";

export default {
  props: ["showCheckout"],
  name: "ConfiguratorEditorView",
  components: {
    Configurator,
  },
  data() {
    return {
      currentConfiguratorMachine: null,
    };
  },
  computed: {
    ...mapGetters([
      "selectedLanguage",
      "getDefaultMachine",
      "machineList",
      "configuratorEditMode",
      "authenticated",
    ]),
    ...mapState(["init", "ready"]),
  },
  methods: {
    ...mapActions([
      "SET_READY",
      "TOGGLE_CONFIGURATOR_MODE",
      "SET_CONFIGURATOR_SELECTED_MACHINE",
      "LOAD_SELECTED_MACHINE_OPTIONS",
      "ADD_DEFAULT_OPTIONS_TO_CART",
      "LOAD_CONFIGURATOR_EDITOR_DOCUMENT",
      "ADD_CONFIGURATOR_EDIT_OPTIONS_TO_CART",
      "CLEAR_CART",
      "SELECT_MACHINE_CATEGORY",
      "VALIDATE_CART_OPTIONS",
    ]),
  },

  async created() {
    console.warn(
      "ConfiguratorEditorView cv     ---------------- created() --------------------"
    );


    console.warn(
      "FOUND QUERY PARAMS In route will check if edit and debug mode is requested"
    );

    const queryEditParams = getUrlQuerySearchParams(this.$route.query);

    const requestId = queryEditParams.get("requestId");
    const documentId = queryEditParams.get("doc");

    await this.LOAD_CONFIGURATOR_EDITOR_DOCUMENT({
      requestId: requestId,
      documentId: documentId,
    }).then(() => {
      this.LOAD_SELECTED_MACHINE_OPTIONS()
        .then(async () => this.ADD_CONFIGURATOR_EDIT_OPTIONS_TO_CART())
        .then(() => {
          this.SELECT_MACHINE_CATEGORY();
          this.SET_READY(true);
          this.TOGGLE_CONFIGURATOR_MODE(true);
          this.VALIDATE_CART_OPTIONS();
          this.$forceUpdate();
        });
    });

    // LOAD
  },

  mounted() {
    console.log("ConfiguratorEditorView, cCreated");
  },
};
</script>
